import { chakra } from "@chakra-ui/react"
import * as React from "react"

const Logo = props => {
  // const { iconColor = 'currentColor', ...rest } = props
  // const color = useToken('colors', iconColor)
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={`auto`}
      viewBox="0 0 241.83 152.71"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M1.62 2.06h51.94v147.18H1.62z" />
          <path
            d="M108.43 17.35H108c-.69 0-1.37-.11-2.07-.11s-1.39.06-2.08.11H66.46v76.5h25.93V72.16h13.5c.74 0 1.47 0 2.21-.11h.31a27.49 27.49 0 000-54.69"
            fill={"#201D1D"}
          />
          <path
            d="M106 27.54a17 17 0 01-14.85 25.35 19.1 19.1 0 01-8.57-2.26 17 17 0 0031.61-8.71c0-5.66-3.93-11.29-8.22-14.38"
            fill="#ffc62c"
          />
          <path
            fill={"#201D1D"}
            d="M181.08 84.44l-16.81-16.07v25.72h-25.72V17.74h25.72l16.08 19.18 16.54-19.18h25.72v76.35h-25.72V68.37l-15.81 16.07z"
          />
          <g fill="#fff">
            <path d="M30.41 96.32h-5.5V92.2h-8.24v-7.63h8.24v-4.8h5.5v4.8H46a3.49 3.49 0 002.27-.47c.29-.3.45-1.24.45-2.81v-.75-.77h5.77l.14 3.65q.18 5.47-1.9 7.48a5.64 5.64 0 01-4.09 1.3H30.41zM24.8 53.59a8.67 8.67 0 012.9-3.66 7.89 7.89 0 013.41-1.49 43.69 43.69 0 015.71-.27h17.35v7.89h-18a7.88 7.88 0 00-3.84.81q-2.07 1.05-2.07 4a5.48 5.48 0 002.05 4.61A9.42 9.42 0 0038.16 67h16v7.72H14.37V67h14.08a9.45 9.45 0 01-3.58-3.86 10.9 10.9 0 01-1-4.64 12.5 12.5 0 01.95-4.94M31.91 35.39a8 8 0 004.56 2V24.14a6.45 6.45 0 00-4.66 2.06 6.76 6.76 0 00-1.59 4.58 6.11 6.11 0 001.69 4.61m-6.67-11.55a11.79 11.79 0 014.37-5.1 14.51 14.51 0 016.1-2.36 38.38 38.38 0 015.86-.26v21.52c3-.13 5-1.15 6.24-3.1a7.88 7.88 0 001.1-4.25 6 6 0 00-3.39-5.88v-7.88a9.61 9.61 0 015.35 2.86q4.29 4 4.3 11.11a16.09 16.09 0 01-3.63 10.4q-3.63 4.5-11.83 4.51c-5.11 0-9-1.35-11.76-4.07a14.23 14.23 0 01-4.1-10.56 16.67 16.67 0 011.39-6.94" />
          </g>
          <g fill={"#201D1D"}>
            <path d="M85.91 121.51c1.27-1.39 1.9-3.59 1.9-6.62 0-2.86-.6-5-1.8-6.51a5.87 5.87 0 00-4.82-2.23 5.69 5.69 0 00-5.68 3.85 13.83 13.83 0 00-.82 5.1 11.77 11.77 0 00.87 4.66 5.73 5.73 0 005.72 3.8 6 6 0 004.63-2.08M83.24 100a9.4 9.4 0 014.62 4.35V100h7.67v28.5c0 3.88-.66 6.8-2 8.77q-3.36 5.05-12.9 5.06a17.61 17.61 0 01-9.4-2.25 8.38 8.38 0 01-4-6.76h8.54a3.48 3.48 0 001.08 2q1.26 1.08 4.26 1.08c2.84 0 4.73-.95 5.69-2.84.62-1.22.93-3.25.93-6.11v-1.94a9.52 9.52 0 01-2.42 2.9 9.8 9.8 0 01-6.1 1.79 11.53 11.53 0 01-9.21-4.09q-3.46-4.06-3.46-11a18.59 18.59 0 013.33-11.26 11 11 0 019.44-4.56 10.31 10.31 0 013.94.69M119.54 99.3h.68v8c-.5 0-.94-.1-1.32-.11h-.94q-4.74 0-6.37 3.08a12 12 0 00-.91 5.35V130h-7.91v-30h7.5v5.24a16.54 16.54 0 013.17-4.11 8.63 8.63 0 015.73-1.85h.37M143.59 122q1.84-2.44 1.85-7c0-3-.62-5.32-1.85-7a7 7 0 00-10.6 0c-1.24 1.63-1.86 3.94-1.86 7s.62 5.33 1.86 7a7 7 0 0010.6 0m6.28 4.35q-3.8 4.69-11.54 4.69t-11.55-4.69a18.66 18.66 0 010-22.57Q130.57 99 138.33 99t11.54 4.76a18.62 18.62 0 010 22.57M167.13 100v18.1a9.65 9.65 0 00.6 3.87c.71 1.52 2.11 2.29 4.2 2.29a5.49 5.49 0 005.48-3.26 11.67 11.67 0 00.77-4.66V100h8v30h-7.63v-4.2c-.07.09-.26.37-.55.82a5.28 5.28 0 01-1 1.21 11 11 0 01-3.56 2.27 12.25 12.25 0 01-4 .6q-6.65 0-9-4.79-1.29-2.64-1.29-7.81V100zM213 108.87a5.63 5.63 0 00-5.2-2.67 5.91 5.91 0 00-5.93 4 15.15 15.15 0 00-.83 5.46c0 3.49.93 6 2.81 7.36a6.38 6.38 0 003.94 1.24 5.73 5.73 0 005.06-2.54 13.26 13.26 0 00.15-12.9m6-5.65q3.68 3.9 3.68 11.42c0 5.28-1.2 9.32-3.59 12.09a11.66 11.66 0 01-9.25 4.17 9.74 9.74 0 01-6-1.79 11.66 11.66 0 01-2.55-2.9v15.65h-7.77V100h7.52v4.44a12.7 12.7 0 012.72-3.09 10.05 10.05 0 016.27-2 11.87 11.87 0 019 3.88" />
          </g>
          <path
            fill="none"
            stroke={"#201D1D"}
            strokeMiterlimit={10}
            strokeWidth={5.345}
            d="M2.67 2.67h236.48v147.37H2.67z"
          />
        </g>
      </g>
    </chakra.svg>
  )
}

export default Logo
